export default {
    methods: {
        patchAPI(path, data = undefined) {
            return this.$axios.patch(`/api${path}`, data, {
                headers: {
                    'Content-Type': 'application/merge-patch+json',
                },
            });
        },
    },
};
